<template>
  <div class="expo">
    <div class="content">
      <img :src="data.url + 'dealer/expo/banner.png'" alt="" class="bg1" />
      <div class="title">
        <img :src="data.url + 'dealer/expo/bg-1.png'" alt="" class="tbg">
        跟随如新，即刻出发<br/>
        卸下身心的重担<br/>
        从治愈中回归自我<br/>
        重新唤醒轻盈活力
      </div>
      <div class="video">
        <video
          ref="myVideo"
          width="100%"
          style="object-fit: contain"
          preload
          controls="controls"
          :poster="data.poster"
          src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/expo-v.M4V"
        ></video>
      </div>
      <div class="downLoadBtn" @click="lookVideo">
        分享和下载视频
        <img :src="data.url + 'right.png'" alt="" />
      </div>
      <div class="part1">
        <img :src="data.url + 'dealer/expo/bg-2.png'" alt="" class="bg3">
        <div class="lunbo">
          <div
            v-if="data.activeIndex == 0"
            class="firstImg swiper-slide swiper-slide-prev"
          >
            <img
              src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/5.png"
              alt=""
            />
          </div>
          <swiper
            ref="mySwiper"
            :slidesPerView="'auto'"
            :centeredSlides="true"
            :spaceBetween="0"
            :slideToClickedSlide="true"
            @activeIndexChange="onSlideChange"
            @swiper="setSwiperRef"
          >
            <template v-for="(item, index) in data.imgList" :key="index">
              <SwiperSlide>
                <img :src="item" alt="" />
              </SwiperSlide>
            </template>
          </swiper>
          <div
            v-if="data.activeIndex == data.imgList.length - 1"
            class="lastImg swiper-slide swiper-slide-next"
          >
            <img
              src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/1.png"
              alt=""
            />
          </div>
        </div>
        <div class="pagination">
          <template v-for="(item, index) in data.imgList" :key="index">
            <span :class="data.activeIndex == index ? 'active' : ''"></span>
          </template>
        </div>
        <div class="partTitle" @click="morePoster">
          更多精彩查看直播相册
          <img :src="data.url + 'right.png'" alt="" />
        </div>
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'dealer/expo/backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { reactive, onMounted } from "vue";
import { baiduTotal } from "../../js/common.js";
import { prviewVideoBox } from "../../js/until";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.mjs";
let mySwiper = null;
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
  imgList: [
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/1.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/2.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/3.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/4.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/5.png",
  ],
  activeIndex: 0, //当前图片下标
  tab:1,//tab下标
  videoUrl:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/expo.M4V',//视频链接
  poster:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/expoCover1.png',
});
const router = useRouter();
let morePoster = () => {
  window.location.href =
    "https://live.photoplus.cn/live/9935250?accessFrom=live#/live";
};
//打开pdf
// let openPdf = () => {
//   window.location.href =
//     "https://nudrive.cn.nuskin.com/file/xylqimib3jvnlna9bucsbigezuz2ctme#";
// };
//返回首页
let backIndex = () => {
  router.replace("/index");
};
//轮播下标
let onSlideChange = (e) => {
  data.activeIndex = e.activeIndex;
};
//自动播放
let setSwiperRef = (swiper) => {
  mySwiper.value = swiper;
  autoplay();
};
//自动播放
let autoplay = () => {
  setInterval(() => {
    if (data.activeIndex == 5) {
      mySwiper.value.slideTo(0, 1);
    } else {
      mySwiper.value.slideNext();
    }
  }, 5000);
};
//查看视频
let lookVideo = () => {
  prviewVideoBox({
    videoUrl: data.videoUrl,
    qcodeUrl: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/expo/expoQr.jpg",
    poster:"https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/expoCover.jpg",
    wangpanPath:"https://nudrive.cn.nuskin.com/file/pwm8hv19b0b2a1zj44xh7mba8e1uon6s#",
  });
};
onMounted(() => {
  baiduTotal();
});
</script>
<style lang="scss" scoped>
.expo {
  width: 100%;
  height: 100%;
  position: relative;
  background: #94D6D7;
  box-sizing: border-box;
  overflow-y: auto;
  .content {
    position: relative;
    width: 100%;
    font-size: 0;
    .bg1 {
      width: 100%;
    }
    .title {
      width: 100%;
      position: relative;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.16rem;
      color: #FFFFFF;
      line-height: 0.3rem;
      letter-spacing: 1px;
      margin-top: 0.16rem;
      .tbg{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .video {
      margin-top: 0.2rem;
      width: 100%;
    }
    .downLoadBtn {
      margin-top: 0.14rem;
    }
    .part1 {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-top: 0.18rem;
      padding-bottom: 0.4rem;
      .bg3 {
        width: 100%;
        position: absolute;
        bottom: 0.6rem;
        left: 0;
      }
      .partTitle {
        width: 1.6rem;
        height: 0.4rem;
        border:1px solid #fff;
        border-radius:0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        margin: 0.32rem auto 0;
        img {
          height: 0.16rem;
          margin-left: 0.04rem;
        }
      }
    }
  }
  .backBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img {
      width: 100%;
    }
  }
}
.lunbo {
  position: relative;
  .firstImg {
    position: absolute;
    top: 50%;
    left: -0.56rem;
    transform: translateY(-50%) scale(0.8);
    img{
      height: 100%;
    }
  }
  .lastImg {
    position: absolute;
    top: 50%;
    right: -0.56rem;
    transform: translateY(-50%) scale(0.8);
  }
}
.swiper {
  margin-top: 0.2rem;
}
.swiper-slide {
  width: 1.62rem !important;
  text-align: center;
  transition: 300ms;
  transform: scale(0.8);
  img {
    width: 100%;
    border-radius: 0.08rem;
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  position: relative;
  img {
    border: 2px solid rgba($color: #bedfec, $alpha: 0.6);
    box-shadow: 0 0.06rem 0.2rem rgba($color: #7abde4, $alpha: 0.6);
  }
  &::before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 0.4rem;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-top: 0.02rem solid;
    border-image: radial-gradient(
        at top center,
        rgba(228, 245, 252, 1),
        rgba(228, 245, 252, 0),
        rgba(228, 245, 252, 0)
      )
      2 2 2 2;
    z-index: 2;
  }
}
.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  span {
    width: 8px;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      width: 14px;
      background: #fff;
    }
  }
}
</style>
